import React, { useEffect, useState } from 'react';
import logo from './images/sytec.webp';
import './App.css';

function App() {
  const [showMatrix, setShowMatrix] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowMatrix((prev) => !prev);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

useEffect(() => {
  const controller = new AbortController(); // Para cancelar la solicitud si el componente se desmonta
  const signal = controller.signal;

  fetch('https://sytec.dev/wp-json/wp/v2/posts', {
    method: 'GET',
    signal: signal,  // Usar el signal para controlar la cancelación
  })
    .then(response => {
      if (!response.ok) {
       console.log('Network response was not connect');
      }
      //console.log(response)
      return response.json();
    })
    .then(data => {
      console.log('Network response connect');
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error:', error);
      }
    });

  return () => {
    controller.abort(); // Abortar la solicitud si el componente se desmonta
  };
}, []);

  const generateMatrix = () => {
    const columns = [];
    const numColumns = 15; // Number of columns with falling numbers
    for (let i = 0; i < numColumns; i++) {
      const numbers = [];
      for (let j = 0; j < 20; j++) {
        const randomNumber = Math.random() < 0.5 ? '0' : '1'; // Randomly generate 0 or 1
        numbers.push(<div key={j}>{randomNumber}</div>);
      }
      columns.push(
        <div className="column" key={i} style={{ animationDelay: `${Math.random() * 3}s` }}>
          {numbers}
        </div>
      );
    }
    return columns;
  };

  return (
    <div className="App">
      <header className="App-header">
        <div id="matrix" className={showMatrix ? "" : "hidden"}>
          {generateMatrix()}
        </div>
        <img src={logo} className="AppIni" alt="logo" />
        <h2 >SYTEC.DEV</h2>
      </header>
    </div>
  );
}

export default App;
